import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { CompleteOrderHandle, GetAllOrdersAdmin, GetSpesificOrder } from '../../../redux/actions/OrdersAdmin';

const CompleteOrder = ({ orderId, pagePag, pageCount, sort, status, ordersCount, searchKey, onSendData }) => {
    const [showEdit, setShowEdit] = useState(false);
    const handleCloseEdit = () => setShowEdit(false);
    const dispatch = useDispatch();
    const handleShowEdit = () => setShowEdit(true);
    const [loader, setLoader] = useState(false);
    const CompleteOrderdata = async () => {
        setLoader(true);
        try {

            await dispatch(CompleteOrderHandle({
                id: orderId,
            }))
        } catch (e) {
            console.log(e)
        } finally {
            
            if (searchKey === "") {
                await dispatch(GetAllOrdersAdmin({
                    pageCount: pageCount,
                    sort: sort,
                    status: status,
                    ordersCount: parseInt(ordersCount)
                }));
            } else {
                await dispatch(GetSpesificOrder({
                    searchValue: searchKey,
                    sort,
                    pageCount: pageCount,
                    ordersCount: parseInt(ordersCount)
                }))
            }
            handleCloseEdit();
            setLoader(false);
            onSendData(false)
        }
    }
    return (
        <div>
            <button onClick={handleShowEdit} style={{ color: "rgb(80, 205, 137)", background: "rgba(60, 196, 65, 0.16)" }} className='btn'>اكمال الطلب</button>
            <Modal show={showEdit} onHide={handleCloseEdit} className='font' centered>
                <Modal.Header>
                    <Modal.Title>اكمال الطلب  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h2>هل تريد اكمال الطلب</h2>
                </Modal.Body>
                <Modal.Footer className='m-auto w-100'>

                    <button className='btn' onClick={CompleteOrderdata} style={{ width: "45%", background: "#FECB44", color: "black" }}>
                        اكمال الطلب
                    </button>
                    <button className='btn' onClick={handleCloseEdit} style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
            {
                loader ? (
                    <div className="loader-overlay">
                        <span className="loader"></span>
                    </div>
                ) : null
            }
        </div>
    )
}

export default CompleteOrder
