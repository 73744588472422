
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetAllBikers, BakerReservations } from '../../../redux/actions/Bikers';
import { format } from 'date-fns';
import Spinner from 'react-bootstrap/Spinner';
import { GetSpesificOrderStart, GetSpesificOrderStartData } from '../../../redux/actions/OrdersAdmin';
import Pagination from '../../../Utils/Pagination'
import Form from 'react-bootstrap/Form';

const CalenderBickers = () => {
    const currentDateselect = new Date();
    const formattedDate = format(currentDateselect, 'yyyy-MM-dd');
    // const [todayDay , setTodayDay] = useState(formattedDate)
    const [todayDay, setTodayDay] = useState('');
    useEffect(() => {
        const currentDateselect = new Date();
        // const formattedDate = format(currentDateselect, 'yyyy-MM-dd');
        setTodayDay(formattedDate);
    }, []);

    // const [dateOrder, setDateOrder] = useState(formattedDate);
    const [bikerSelect, setBikerSelect] = useState("");
    const [loading, setLoading] = useState(false);
    const [showSelectedDates, setShowSelectedDates] = useState(false);


    const dispatch = useDispatch();

    const fetchAllBikers = async () => {
        await dispatch(GetAllBikers());
    };

    useEffect(() => {
        fetchAllBikers();
    }, []);

    // useEffect(() => {
    //     if (bikerSelect || todayDay) {
    //         setD("");
    //         setLoading(true); // Set loading to true when making the reservation request
    //         dispatch(BakerReservations({
    //             id: bikerSelect,
    //             date: todayDay,
    //         })).then(() => {
    //             setLoading(false); // Set loading to false when data is received
    //         }).catch(() => {
    //             setLoading(false); // Set loading to false in case of an error
    //         });
    //     }
    // }, [todayDay, bikerSelect]);
    const [showNoData, setShowNoData] = useState(false); // State to control "No data" message

    useEffect(() => {
        if (bikerSelect || todayDay) {
            setLoading(true);
            dispatch(BakerReservations({
                id: bikerSelect,
                date: todayDay,
            })).then(() => {
                setLoading(false);
                if (filterData && filterData.length === 0) {
                    setShowNoData(true); // Set showNoData to true if filterData is empty
                    setTimeout(() => setShowNoData(false), 1000); // Hide "No data" message after 3 seconds
                }
            }).catch(() => {
                setLoading(false);
            });
        }
    }, [todayDay, bikerSelect]);


    const bikers = useSelector(state => state.GetBikers.AllBikers);

    const getBikerOrders = useSelector(state => state.GetBikers.Bikers_Orders);

    useEffect(() => {
        setFilterData(getBikerOrders)
    }, [getBikerOrders])

    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(null);

    useEffect(() => {

        setSelectedDate(currentDate);
    }, [currentDate]);

    const getDayOfMonth = (date) => {
        return date.getDate();
    };

    const getPreviousDates = (numDays) => {
        const previousDates = [];
        for (let i = 1; i <= numDays; i++) {
            const previousDate = new Date(currentDate);
            previousDate.setDate(currentDate.getDate() - i);
            previousDates.push(previousDate);
        }
        return previousDates;
    };

    const getNextDates = (numDays) => {
        const nextDays = [];
        for (let i = 1; i <= numDays; i++) {
            const nextDate = new Date(currentDate);
            nextDate.setDate(nextDate.getDate() + i);
            nextDays.push(nextDate);
        }
        return nextDays;
    };

    const getDayName = (date) => {
        const daysOfWeek = ['الأحد', 'الإثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت'];
        const dayIndex = date.getDay();
        return daysOfWeek[dayIndex];
    };

    const getDayInfo = (date) => {
        return {
            date: date.toDateString().slice(7, 10),
            name: getDayName(date)
        };
    };



    const handleDayClick = (date) => {
        if (selectedDate && selectedDate.toDateString() === date.toDateString()) {
            setSelectedDate(null);
        } else {
            setSelectedDate(date);
        }

        const formattedDate = date.toLocaleDateString('en-CA');
        setTodayDay(formattedDate);
        setCurrentDate(date);
        setShowSelectedDates(true);

        // Check if the clicked date is today's date
        const today = new Date();
        const isToday = date.toDateString() === today.toDateString();

        // Toggle background color based on whether the clicked date is today's date
        const dayElements = document.getElementsByClassName("day-container");
        for (let element of dayElements) {
            if (element.id === format(date, 'yyyy-MM-dd')) {
                element.style.background = isToday ? 'red' : 'transparent';
            } else {
                element.style.background = 'transparent';
            }
        }


        setTodayDay(formattedDate);
        if (filterData && filterData.length === 0) {
            setShowNoData(true); // Set showNoData to true if filterData is empty
            setTimeout(() => setShowNoData(false), 1000); // Hide "No data" message after 3 seconds
        }
        // Fetch data for the selected day
    };





    // const handleDateChange = (e) => {
    //     console.log(e)
    //     if (e !== "") {
    //         const selectedDate = new Date(e);
    //         const formattedDate = format(selectedDate, 'yyyy-MM-dd'); // Convert the selected date back to the desired format
    //         setCurrentDate(selectedDate);
    //         // setDateOrder(e); 
    //         setTodayDay(formattedDate)
    //     }else{
    //         setTodayDay(formattedDate)
    //         setShowNoData(true);
    //         // setCurrentDate(currentDate)
    //     }
    // };


    const handleDateChange = (e) => {
        if (e !== "") {
            const selectedDate = new Date(e);
            const formattedDate = format(selectedDate, 'yyyy-MM-dd'); // Convert the selected date back to the desired format
            setCurrentDate(selectedDate);
            setTodayDay(formattedDate);
            setShowNoData(true);
        } else {
            const currentDateselect = new Date();
            const formattedDate = format(currentDateselect, 'yyyy-MM-dd');
            setCurrentDate(currentDateselect);
            setTodayDay(formattedDate);
            setShowNoData(true);
        }
    };


    const colorMap = {
        COMPLETED: "rgba(80, 205, 137, 1)",
        CANCELLED: "#EB5757",
        CREATED: "#AD6423",
        STARTED: "#E87610",
        BIKER_ON_THE_WAY: "#AD6423",
        BIKER_ARRIVED: "#6D5743",
    };
    const colorMapBack = {
        COMPLETED: "#3CC44129",
        CANCELLED: "#EB575729",
        CREATED: "#AD642329",
        STARTED: "#E8761029",
        BIKER_ON_THE_WAY: "#AD642329",
        BIKER_ARRIVED: "#6D574329",
    };


    const [d, setD] = useState("");


    const fetchAllDataOrders = async () => {
        setLoading(true)
        setD("data");
        setTodayDay(formattedDate)
        await dispatch(GetSpesificOrderStart({
            pageCount: 1,
            date: formattedDate
        }));
    }

    useEffect(() => {
        fetchAllDataOrders();
    }, [])

    const dataStart = useSelector(state => state.OrdersAdminReducer.OrdersAdmin);
    const [currentPage, setCurrentPage] = useState(1);

    const getPage = async (page) => {
        setCurrentPage(page)
        await dispatch(GetSpesificOrderStart({
            pageCount: page,

        }));
    };



    useEffect(() => {
        // if (dataStart && dataStart.ordersCount) {
        setLoading(true)
        if (dataStart && dataStart.ordersCount) {
            dispatch(GetSpesificOrderStartData({
                limit: dataStart.ordersCount,
                date: todayDay,
                id: bikerSelect,
            }));
        }
        // }
    }, [todayDay, bikerSelect])


    const [filterData, setFilterData] = useState([]);
    useEffect(() => {
        if (dataStart && dataStart.orders) {
            setFilterData(dataStart.orders)
        }
    }, [dataStart])




    const resetData = async () => {
        setLoading(true)
        fetchAllDataOrders();
        setBikerSelect("");
        setD("data")
        handleDateChange(formattedDate);
        setTodayDay(formattedDate);
        setLoading(false)
    }


    useEffect(() => {
        if (bikerSelect === "all") {
            resetData();
            setD("data");
            dispatch(GetSpesificOrderStart({
                pageCount: 1,
                date: formattedDate
            }));
        }
    }, [bikerSelect])

    useEffect(() => {
        setLoading(true);
        const currentDateselect = new Date();
        // const formattedDate = format(currentDateselect, 'yyyy-MM-dd');
        setTodayDay(formattedDate);

        setLoading(false)
    }, []);


    const isToday = currentDate.toDateString() === selectedDate?.toDateString();




    console.log(filterData)




    return (
        <div className='w-100 p-2 calender' style={{ maxHeight: "80vh", overflowY: "scroll", overflowX: "hidden" }}>
            <div className='flexItem'>
                <div className='font' style={{ lineHeight: "8px" }}>
                    <p style={{ color: "#BDBDBD" }}>جدول حجوزات البايكر</p>
                </div>
                <input type="date" name="dateToday" value={todayDay} onChange={(e) => handleDateChange(e.target.value)} style={{ padding: "5px", outline: "none", border: "none", borderRadius: "5px" }} />
                <div>
                    <select onChange={(e) => setBikerSelect(e.target.value)}>
                        <option value="all" defaultChecked>الوضع الابتدائي</option>
                        {
                            bikers && bikers.bikers ? (
                                bikers.bikers.map((biker) => {
                                    if (biker.is_active === true) {
                                        return (
                                            <option value={biker.id} key={biker.id}>{biker?.user?.first_name}</option>
                                        );
                                    }
                                    return null;
                                })
                            ) : null
                        }
                    </select>
                    {/* {bikerSelect} */}
                </div>
            </div>

            <div className='text-center'>
                <div className='mt-5'>
                    <div className='flexItem font' style={{ cursor: "pointer" }}>
                        {getNextDates(5).reverse().map((date, index) => (
                            <div
                                key={index}
                                onClick={() => handleDayClick(date)}
                                style={{
                                    background: selectedDate && selectedDate.toDateString() === date.toDateString() ? "red" : "transparent", // تغيير الخلفية إلى الشفافية
                                    width: "50px",
                                    color: "black",
                                    borderRadius: "20px",
                                    padding: "5px"
                                }}
                                className="day-container"
                            >
                                <p>{getDayInfo(date).date}</p>
                                <p style={{ color: "#A1A5B7" }}>{getDayInfo(date).name}</p>
                            </div>
                        ))}
                        <div
                            onClick={() => handleDayClick(currentDate)}
                            style={{
                                background: isToday ? "#3CC441" : "transparent", // تغيير الخلفية إلى الشفافية
                                width: "50px",
                                color: "black",
                                borderRadius: "20px",
                                padding: "5px",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <p>{getDayOfMonth(currentDate)}</p>
                            <p>{getDayName(currentDate)}</p>
                        </div>
                        {getPreviousDates(5).map((date, index) => (
                            <div
                                key={index}
                                onClick={() => handleDayClick(date)}
                                style={{
                                    background: selectedDate && selectedDate.toDateString() === date.toDateString() ? "red" : "transparent", // تغيير الخلفية إلى الشفافية
                                    width: "50px",
                                    color: "black",
                                    borderRadius: "20px",
                                    padding: "5px"
                                }}
                                className="day-container"
                            >
                                <p>{getDayInfo(date).date}</p>
                                <p style={{ color: "#A1A5B7" }}>{getDayInfo(date).name}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>


            <div className='bickerBooking d-flex mt-5'>
                <div style={{ width: "100%" }}>
                    <div className='row justify-content-start align-items-center typebooking'>
                        {loading === true ? (
                            <Spinner animation="border" role="status" className='text-center m-auto'>
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        ) : (

                            filterData && filterData.length > 0 ? (

                                filterData.map((biker_order_details) => (

                                    <div className='col-lg-6' key={biker_order_details.id}>
                                        <div className='flexItem'>
                                            <div className='clientData font w-100 mt-4' style={{ lineHeight: "" }} >
                                                <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                                                    <p style={{ color: "#BDBDBD", marginLeft: "5px" }}>{biker_order_details.slot.name}</p>
                                                    <p style={{ background: colorMapBack[biker_order_details.status], color: colorMap[biker_order_details.status], borderRadius: "10px", padding: "10px" }}>{biker_order_details.status}</p>
                                                </div>
                                                <p style={{ color: "#7E8299" }}><span>{biker_order_details.vehicle.brand.name_ar} -
                                                    <a
                                                        href={`https://www.google.com/maps?q=${biker_order_details.address.latitude},${biker_order_details.address.longitude}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                                                    >
                                                        {biker_order_details.address.address}
                                                    </a>
                                                </span></p>
                                                <p><span style={{ color: "#3E97FF" }}> اسم العميل :</span> {biker_order_details.customer.user.first_name}-{biker_order_details.customer.user.last_name}</p>
                                                <p><span style={{ color: "#3E97FF" }}> جوال العميل :</span> {biker_order_details.customer.user.phone}</p>
                                                <div className='d-flex  justify-content-start'>
                                                    <span style={{ color: "#3E97FF" }}>الخدمات الاضافيه:</span>
                                                    {
                                                        biker_order_details.services && biker_order_details.services.length > 0 ? (
                                                            biker_order_details.services.map((service) => {
                                                                return (
                                                                    <p className='mx-1'>{service.name}</p>
                                                                )
                                                            })
                                                        ) : <p>لا يوجد خدمات في هذا الاوردر</p>
                                                    }
                                                </div>
                                            </div>
                                            <div className='w-100'>
                                                <div className='requestNum font p-3 flexItem' style={{ borderRadius: "10px", background: "#F1F1F2", color: "#7E8299" }}>
                                                    <span>رقم الطلب : {biker_order_details.number}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                showNoData ? (

                                    <Spinner animation="border" role="status" className='text-center m-auto'>
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                ) : (
                                    <p className="text-center font">لا توجد بيانات</p>
                                )
                            )
                        )}
                    </div>
                </div>
            </div>
            {/* {d === "data" && <Pagination onPress={getPage} countsPage={dataStart.ordersCount / 10} />} */}


        </div>
    );
};

export default CalenderBickers;
