import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import { useParams } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { useDispatch, useSelector } from 'react-redux';
import { BikerProfileUpdate, GetBikerProfile } from '../../redux/actions/Bikers';
import LocatiobStart from './LocationStart'
import MapEditArea from '../../Utils/MapEditArea';
import { UpdateRole, UpdateUser } from '../../redux/actions/Users';
import { MdCatchingPokemon } from 'react-icons/md';
import { AddPremission, RemovePremission } from '../../redux/actions/PremissionAction';
import { MdDelete } from "react-icons/md";

const BikerDetails = () => {
    const [loading, setLoading] = useState(false)

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
    const [coordinates, setCoordinates] = useState(null);
    const [startLat, setStartLat] = useState(null);
    const [startLng, setStartLng] = useState(null);
    const [passwordData, setPassword] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');


    const [show, setShow] = useState(false);

    const handleShow = () => {
        setShow(!show)
    }











    const params = useParams();
    // console.log(params);


    const dispatch = useDispatch();

    const GetData = async () => {
        await dispatch(GetBikerProfile({
            id: params.id,
            role: params.role
        }))
    }

    useEffect(() => {
        GetData();
    }, [])

    const bikerProfileData = useSelector(state => state.GetBikers.Biker_Profile);


    console.log(bikerProfileData)


    useEffect(() => {
        // Set the input values when bikerProfileData is available
        if (bikerProfileData && bikerProfileData.user) {
            setFirstName(bikerProfileData.user.first_name || '');
            setLastName(bikerProfileData.user.last_name || '');
            setEmail(bikerProfileData.user.email || '');
            setPhone(bikerProfileData.user.phone || '');
            setPhoneNumber(bikerProfileData.user.phone || '');
            // You can set other input values if needed
        }
    }, [bikerProfileData]);


    const handlePhoneNumberChange = (newPhoneNumber) => {
        // const inputValue = e.target.value;
        const isValid = /^\+966\d{5}$/.test(newPhoneNumber);

        setPhoneNumber(`+${newPhoneNumber}`);
        setIsValidPhoneNumber(isValid);
    };

    const handleUpdateProfile = async () => {
        try {
            setLoading(true);

            // if (!firstName || !lastName || !phone || !email) {
            //     console.error('الرجاء ملء جميع الحقول المطلوبة');
            //     return;
            // }

            // if (!isValidPhoneNumber) {
            //     console.error('رقم الهاتف غير صحيح');
            //     return;
            // }


            if (params.role === "BIKER") {
                await dispatch(BikerProfileUpdate({
                    id: params.id,
                    first_name: firstName,
                    last_name: lastName,
                    phone: phoneNumber,
                    email: email,
                    start_latitude: startLat,
                    start_longitude: startLng,
                    // password: passwordData,
                }));
            } else if (params.role === "ADMIN") {
                if (passwordData === "") {
                    await dispatch(UpdateUser({
                        id: params.id,
                        first_name: firstName,
                        last_name: lastName,
                        phone: phoneNumber,
                        email: email,
                        // password: passwordData,
                    }));
                } else {
                    await dispatch(UpdateUser({
                        id: params.id,
                        first_name: firstName,
                        last_name: lastName,
                        phone: phoneNumber,
                        email: email,
                        password: passwordData,
                    }));
                }
                // addPremissions();
            } else {
                await dispatch(UpdateUser({
                    id: params.id,
                    first_name: firstName,
                    last_name: lastName,
                    phone: phoneNumber,
                    email: email,
                }));
            }

        } catch (error) {
            console.error('Error updating profile:', error);
        } finally {
            setLoading(false);
            if (params.role !== "BIKER") {
                setTimeout(() => {
                    window.location.href = "/admin/users";
                }, 1500)
            } else {
                setTimeout(() => {
                    window.location.href = "/admin/managebiker";
                }, 1500)
            }
        }
    }


    const [selectedLocation, setSelectedLocation] = useState({ lat: 0, lng: 0 });


    const handleLocationSelect = (location) => {
        setSelectedLocation(location);
    }



    const [lat, setLat] = useState(null);
    const [lang, setLang] = useState(null);

    useEffect(() => {
        if (bikerProfileData) {
            setLat(bikerProfileData.start_latitude)
            setLang(bikerProfileData.start_longitude)
        }
    }, [bikerProfileData])

    const handleCoordinatesChange = (newCoordinates) => {
        setCoordinates(newCoordinates);
    };

    useEffect(() => {
        if (coordinates !== null) {
            setStartLat(coordinates.lat)
            setStartLng(coordinates.lng)
        }

    }, [coordinates]);


    useEffect(() => {
        if (selectedLocation) {
            setStartLat(selectedLocation.lat)
            setStartLng(selectedLocation.lng)
        }
    }, [selectedLocation])


    const biker_profile = useSelector(state => state.GetBikers.Profile_Update_Bikers)






    const [role, setRole] = useState("");


    // const ChangeRole = async () => {
    //     setLoading(true)
    //     try {
    //         await dispatch(UpdateRole(
    //             {
    //                 "role": role,
    //                 "user_id": params.id
    //             }
    //         ))
    //     } catch (e) {
    //         console.log(e);
    //     } finally {
    //         setLoading(false)
    //     }
    // }

    const ChangeRole = async (newRole) => {
        setLoading(true);
        try {
            await dispatch(
                UpdateRole({
                    role: newRole,
                    user_id: params.id,
                })
            );
            setRole(newRole); // تحديث الدور في واجهة المستخدم
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
            GetData();
        }
    };


    const addPremissions = async () => {
        try {
            await dispatch(AddPremission({
                user_id: params.id,
                premessions: selectedOptions
            }))
        } catch (e) {

        } finally {
            await dispatch(GetBikerProfile({
                id: params.id,
                role: params.role
            }))
            setSelectedOptions([]);
        }
    }




    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleOptionChange = (event) => {
        const optionValue = event.target.value;
        if (optionValue === "all") {
            setSelectedOptions([
                "order",
                "user",
                "promo-code",
                "subscription",
                "vehicle",
                "color",
                "package",
                "service",
                "notification",
                "banner",
                "slots",
                "app-constants",
                "sms",
                "gift",
                "biker",
                "about_us",
                "point",
                "storage",
                "auth",
                "social-media",
                "terms-conditions",
                "privacy-policy",
                "support",
                "questions-answers",
                "working-area",
                "review-order",
                "order-invoice",
            ])
        } else {
            if (selectedOptions.includes(optionValue)) {
                // إزالة الخيار إذا كان محددًا بالفعل
                setSelectedOptions(selectedOptions.filter(option => option !== optionValue));
            } else {
                // إضافة الخيار إذا لم يكن محددًا بالفعل
                setSelectedOptions([...selectedOptions, optionValue]);
            }
        }


    };

    const handleRemoveOption = (optionValue) => {
        setSelectedOptions(selectedOptions.filter(option => option !== optionValue));
    };


    const handleRemovePremission = async (pre) => {
        console.log(pre);
        try {
            await dispatch(RemovePremission({
                user_id: params.id,
                premessions: [`${pre}`]
            }))
        } catch (e) {
            console.log(e)
        } finally {
            await dispatch(GetBikerProfile({
                id: params.id,
                role: params.role
            }))
        }
    }



    var adminLogIn = {};

    if (localStorage.getItem('user')) {
        adminLogIn = JSON.parse(localStorage.getItem('user'));
    }




    return (
        <div className='font'>
            <div className='flexItem mt-2'>
                <div>
                    <p>
                        <span style={{ color: "#BDBDBD" }}>ادارة البايكرز &gt;</span>
                        {
                            bikerProfileData && bikerProfileData.user ? (
                                <span>{bikerProfileData.user.first_name}</span>
                            ) : null
                        }
                    </p>
                </div>

                <div>
                    <button className='btn submitButton w-100' onClick={handleUpdateProfile}>حفظ التعديلات</button>
                </div>
            </div>
            <div className='mt-3'>
                <div className='bikerEditTop'>
                    المعلومات الاساسيه
                </div>
                {
                    bikerProfileData && bikerProfileData.user ? (
                        <div className='flexItem mt-3' style={{ background: "white", padding: "10px", borderRadius: "10px" }}>
                            <div>
                                <div>
                                    <label>الاسم الاول : </label>
                                    <input type='text' className='w-100 mt-2 textInputBiker' onChange={(e) => setFirstName(e.target.value)} value={firstName} />
                                </div>

                                {
                                    params.role !== "CLIENT" ? (<div className='mt-1'>
                                        <label>الاسم الاخير : </label>
                                        <input type='text' className='w-100 mt-2 textInputBiker' onChange={(e) => setLastName(e.target.value)} value={lastName} />
                                    </div>) : null
                                }
                                {
                                    params.role === "ADMIN" || params.role === "CLIENT" ? (
                                        <div className='mt-3'>
                                            <div>
                                                <div>
                                                    <label>القاعده :ADMIN</label>
                                                    <Form.Check
                                                        type="switch"
                                                        id={`custom-switch`}
                                                        checked={bikerProfileData?.user?.roles?.includes("ADMIN")}
                                                        onChange={() => ChangeRole('ADMIN')} 
                                                        
                                                    />
                                                </div>
                                                <div>
                                                    <label>القاعده :CLIENT</label>
                                                    <Form.Check
                                                        type="switch"
                                                        id={`custom-switch`}
                                                        checked={bikerProfileData?.user?.roles?.includes("CLIENT")}
                                                        onChange={() => ChangeRole('CLIENT')}
                                                    />
                                                </div>
                                            </div>
                                            {/* <label>القاعده : {params.role}</label>
                                            <div className='d-flex'>
                                                <Form.Select aria-label="Default select example" style={{ background: "#EFF1F9" }} onChange={(e) => setRole(e.target.value)}>
                                                    <option hidden>تغيير القاعده الخاصه ب {firstName}</option>
                                                    <option value="ADMIN">ادمن</option>
                                                    <option value="CLIENT">عميل</option>
                                                </Form.Select>
                                                {
                                                    role !== "" ? (
                                                        <button className='btn mx-2' style={{ background: "rgb(254, 203, 68)" }} onClick={ChangeRole}>تاكيد</button>
                                                    ) : null
                                                }
                                            </div> */}
                                        </div>
                                    ) : null
                                }
                            </div>
                            <div>
                                <div className='mt-1'>
                                    <label>رقم الهاتف : </label>
                                    <PhoneInput
                                        country={'sa'}
                                        countryCodeEditable={false}
                                        disableDropdown={true}
                                        value={phoneNumber}
                                        onChange={handlePhoneNumberChange}
                                    />
                                    {/* {error && <small style={{ color: 'red' }}>{error}</small>} */}
                                </div>
                                <div className='mt-1'>
                                    <label>البريد الالكتروني : </label>
                                    <input type='email' className='w-100 mt-2 textInputBiker' onChange={(e) => setEmail(e.target.value)} value={email} />
                                </div>
                                {
                                    params.role === "ADMIN" ? (
                                        <div className='mt-1'>
                                            <label>كلمة السر : </label>
                                            <input type='password' className='w-100 mt-2 textInputBiker' onChange={(e) => setPassword(e.target.value)} />
                                        </div>
                                    ) : null
                                }
                            </div>
                        </div>
                    ) : null



                }
            </div>




            {
                params.role === "BIKER" ? (
                    <div className='mt-3'>
                        <div className='bikerEditTop flexItem'>
                            <div>نقطة الانطلاق</div>
                            <div className='w-25'>
                                <button className='btn submitButton w-100' onClick={handleShow}>تعديل نقطة انطلاقة البايكر</button>
                            </div>
                        </div>
                        {
                            <div className='p-3 mt-3' style={{ background: "white", padding: "10px", borderRadius: "10px" }}>
                                {/* <LocatiobStart onCoordinatesChange={handleCoordinatesChange} /> */}
                                <MapEditArea onLocationSelect={handleLocationSelect} lat={lat} lang={lang} />
                            </div>
                        }
                    </div>
                ) : null
            }


            {
                params.role === "ADMIN" ? (
                    <div style={{ background: "white", padding: "10px", borderRadius: "10px", marginTop: "10px" }}>
                        {
                            bikerProfileData && bikerProfileData.user ? (
                                <p>الصلاحيات المتاحه حاليا للامن {bikerProfileData.user.first_name} : </p>
                            ) : null
                        }
                        <div className='d-flex flex-wrap '>
                            {
                                bikerProfileData && bikerProfileData.premessions ? (
                                    bikerProfileData.premessions.map((pre, index) => {
                                        return (
                                            <div key={index + 1}
                                                style={{ textAlign: "center", marginTop: "10px", marginRight: "10px", width: "15%", padding: "10px", background: "rgb(255, 250, 236)" }}
                                                data-value={pre}
                                            // onClick={(e) => handleRemovePremission(e.target.getAttribute('data-value'))}
                                            >
                                                {pre}

                                                <MdDelete
                                                    data-or={pre}
                                                    style={{ marginRight: "10px", cursor: "pointer" }}
                                                    onClick={(e) => handleRemovePremission(e.currentTarget.getAttribute('data-or'))}
                                                />

                                            </div>
                                        )
                                    })
                                ) : null
                            }
                        </div>
                        <div className='d-flex'>
                            <div className='w-75'>
                                {
                                    params.role === "ADMIN" ? (
                                        <Form.Select aria-label="Default select example" className='w-50 mt-3' onChange={handleOptionChange}>
                                            <option hidden>اختر صلاحيات الادمن </option>
                                            <option value="all">اعطاء كل الصلاحيات</option>
                                            <option value="order">Order</option>
                                            <option value="user">User</option>
                                            <option value="promo-code">Promo Code</option>
                                            <option value="subscription">Subscription</option>
                                            <option value="vehicle">Vehicle</option>
                                            <option value="color">Color</option>
                                            <option value="package">Package</option>
                                            <option value="service">Service</option>
                                            <option value="notification">Notification</option>
                                            <option value="banner">Banner</option>
                                            <option value="slots">Slots</option>
                                            <option value="app-constants">App Constants</option>
                                            <option value="sms">SMS</option>
                                            <option value="gift">Gift</option>
                                            <option value="biker">Biker</option>
                                            <option value="about_us">About Us</option>
                                            <option value="variable">variable</option>
                                            <option value="point">Point</option>
                                            <option value="storage">Storage</option>
                                            <option value="auth">Authentication</option>
                                            <option value="social-media">Social Media</option>
                                            <option value="terms-conditions">Terms & Conditions</option>
                                            <option value="privacy-policy">Privacy Policy</option>
                                            <option value="support">Support</option>
                                            <option value="questions-answers">Questions & Answers</option>
                                            <option value="working-area">Working Area</option>
                                            <option value="review-order">Review Order</option>
                                            <option value="order-invoice">Order Invoice</option>
                                        </Form.Select>
                                    ) : null
                                }
                                <div className=' mt-2 w-50'>
                                    {
                                        selectedOptions.map((option) => {
                                            return (
                                                <div key={option}
                                                    style={{ marginRight: "10px", marginTop: "20px", padding: "10px", textAlign: "center", width: "50%", flexWrap: "wrap", cursor: 'pointer', background: "rgb(255, 250, 236)" }}
                                                    onClick={() => handleRemoveOption(option)}>
                                                    {option}×
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            </div>
                            {
                                selectedOptions.length > 0 ? (<div>
                                    <button className='btn submitButton w-100' onClick={addPremissions}>تفعيل الصلاحيات للادمن</button>
                                </div>) : null
                            }
                        </div>

                    </div>
                ) : null
            }




            {/* <div className='mt-3'>
                <div className='bikerEditTop'>
                    الاوقات المتاحه
                </div>
                <div className='flexItem p-3'>
                    <div className='w-50 mx-3' >
                        <div className='timeAvailData w'>
                            <p>8:00</p>
                            <p> <Form.Check
                                type="switch"
                                id="custom-switch"
                            /></p>
                        </div>
                        <div className='timeAvailData'><p>8:00</p>
                            <p> <Form.Check
                                type="switch"
                                id="custom-switch"
                            /></p></div>
                        <div className='timeAvailData'><p>8:00</p>
                            <p> <Form.Check
                                type="switch"
                                id="custom-switch"
                            /></p></div>
                    </div>
                    <div className='w-50'>
                        <div className='timeAvailData w'>
                            <p>8:00</p>
                            <p> <Form.Check
                                type="switch"
                                id="custom-switch"
                            /></p>
                        </div>
                        <div className='timeAvailData'><p>8:00</p>
                            <p> <Form.Check
                                type="switch"
                                id="custom-switch"
                            /></p></div>
                        <div className='timeAvailData'><p>8:00</p>
                            <p> <Form.Check
                                type="switch"
                                id="custom-switch"
                            /></p></div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default BikerDetails

